import React from "react";
import Header from "../components/header/header.js";
import WhoItsForGrid from "../components/whoItsFor/whoItsForGrid";

const WhoItsFor = () => {
  return (
    <>
      <Header
        title="A chi è rivolto?"
        metaDescription="A chi vuole offrire ai propri clienti un servizio WiFi all’avanguardia, fruibile e che li tenga al riparo dalla normativa vigente."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <h2>
              A chi vuole offrire ai propri clienti un servizio WiFi
              all’avanguardia, fruibile e che li tenga al riparo dalla normativa
              vigente.
            </h2>
            <p className="description">
              <br />
              <br />A tutti gli utenti che utilizzano Connect@You come strumento
              per accedere alla WiFi con il proprio device in modo semplice,
              veloce e gratuito. Coccola il tuo cliente e fallo sentire a casa
              sua, grazie alla configurazione della landing page nella lingua
              impostata sul proprio device.
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <WhoItsForGrid />
      <div className="section" />
    </>
  );
};

export default WhoItsFor;
