import React, { useState, useContext } from "react";
import WhoItsForIcon from "./whoItsForIcon";
import { ThemeContext } from "../../context/ThemeContext";

import "./style.scss";

const WhoItsForGrid = () => {
  const theme = useContext(ThemeContext);
  const [WAYSTOACCESSES, setWAYSTOACCESSES] = useState([
    {
      name:"Mezzi di trasporto",
      icon: "Bus",
      alt: "Bus Icon",
    },
    {
      name:"Camping e villaggi",
      icon: "Tent",
      alt: "Camping Tent Icon",
    },
    {
      name:"Bar",
      icon: "Coffee",
      alt: "Coffee Icon",
    },
    // {
    //   icon: "Church",
    //   alt: "Church Icon",
    // },
    // {
    //   icon: "Bike",
    //   alt: "Bike Icon",
    // },
    {
      name:"Stabilimenti balneari",
      icon: "Umbrella",
      alt: "Umbrella Icon",
    },
    {
      name:"Musei",
      icon: "Temple",
      alt: "Temple Icon",
    },
    {
      name:"Centri sportivi",
      icon: "Barbell",
      alt: "Barbell Icon",
    },
    {
      name:"Centri medici",
      icon: "Mask",
      alt: "Mask Icon",
    },
    {
      name:"Centri estetici",
      icon: "Scissors",
      alt: "Scissors Icon",
    },
    {
      name:"Città",
      icon: "Buildings",
      alt: "Buildings Icon",
    },
    {
      name:"Negozi e centri commerciali",
      icon: "Cart",
      alt: " Shopping Cart Icon",
    },
    {
      name:"Eventi",
      icon: "Ticket",
      alt: " Ticket Icon",
    },
    {
      name:"Aeroporti",
      icon: "Airplane",
      alt: "Airplane Icon",
    },
    {
      name:"Scuole",
      icon: "Graduation",
      alt: "Graduation Hat Icon",
    },
    {
      name:"Fiere e centri congressi",
      icon: "Flags",
      alt: "Flags Icon",
    },
    {
      name: "Strutture ricettive",
      icon: "Bed",
      alt: "Bed Icon",
    },
    {
      name: "Ristoranti",
      icon: "Fork",
      alt: "Fork Icon",
    },
  ]);

  function changeColor(index, color) {
    let newArray = [...WAYSTOACCESSES];
    newArray[index].color = color;
    setWAYSTOACCESSES(newArray);
  }

  return (
    <>
      <div className="container">
        <div className="columns is-multiline whoItsForGrid is-mobile">                
          {WAYSTOACCESSES.map((i, index) => (
            <div className={`column ${theme.isMobile ? "is-4": "is-2"}`} key={index}>
              {i.color === "white" ? (
                <div className="balloon">{i.name}</div>
              ) : null}
              <div className="gridButton">
                <div
                  className="gridIcon"
                  role="button"
                  tabIndex={index}
                  onMouseEnter={() => changeColor(index, "white")}
                  onMouseLeave={() => changeColor(index, "#23b6ff")}
                >
                  <WhoItsForIcon icon={i.icon} color={i.color} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WhoItsForGrid;
